@import "@/assets/constants/index.scss";
.table-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  word-break: break-word;

  & > * {
    margin-right: 2px;
  }

  .filter-filled:after {
    position: absolute;
    top: -2px;
    right: -2px;
    content: "";
    width: 4px;
    height: 4px;
    border-radius: 10px;
    background-color: $c-primary;
  }

  svg {
    cursor: pointer;
    use[fill="#000"] {
      stroke: $c-blue-grey1;
      fill: $c-blue-grey1;
    }
    &.sorting-active {
      use[fill="#000"] {
        stroke: $c-primary;
        fill: $c-primary;
      }
    }

    &.sorting-desc {
      transform: rotate(180deg);
    }
  }
}
