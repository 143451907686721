@import "@/assets/constants/index.scss";
.range-filter.form-parent {
  padding: 12px;
  min-height: 250px;

  .el-col {
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }
  }

  .el-form-item {
    width: auto;
  }

  .el-input {
    width: 104px;
  }

  .button-box {
    margin: 0 -12px -12px -12px;
    width: calc(100% + 24px);
  }
}
