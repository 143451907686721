@import "@/assets/constants/index.scss";
.status-rectangle {
  width: 130px;
  min-width: 130px;
  padding: 2px 0px;
  font-size: 14px;
  font-weight: $weight-semi-bold;
  text-align: center;
  white-space: nowrap;
  border-radius: 8px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.status-created {
    color: $c-yellow;
    background-color: $c-amber;
  }

  &.status-pending {
    color: $c-indigo;
    background-color: $c-indigo2;
  }

  &.status-activated {
    color: $c-green;
    background-color: $c-green2;
  }

  &.status-deactivated {
    color: $c-blue-grey1;
    background-color: $c-blue-grey4;
  }
}
